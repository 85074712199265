import React from "react"
import PageLayout from "../layouts/pageLayout"
import SEO from "../components/utils/Seo"
import { About, Hero, Services, Contact, Testimonials } from "../components"

const IndexPage = () => (
  <PageLayout>
    <SEO title="Home" description="Unitech Pest and Bed Bug Services is a full service, professional pest management company located in St. Louis, Missouri.  We stand prepared to eliminate unwanted pests in your home or business." />
    <Hero />
    <About />
    <Services />
    <Testimonials />
    <Contact />
  </PageLayout>
)

export default IndexPage
